<template>
  <div class="profile">
    <div class="flex align-center direction-column" v-if="submitted">
      <h2 class="profile__subm mg-b-20">{{$t('store.thanks_for_submit')}}</h2>

      <div class="title-gray">{{$t('store.thanks_description')}}</div>
      <button class="btn btn--red mg-t-30">{{$t('store.thanks')}}</button>
    </div>
    <form class="container" v-else @submit.prevent="savelAll">
      <div v-if="store.id" class="profile__title" ref="title">
        <h3>{{$t('store.profile_management')}}</h3>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-20">{{$t('store.account_management')}}</div>
        <div v-if="store.id" class="title-red mg-b-5">{{$t('store.joined_on')}}</div>
        <div v-if="store.id" class="text-dark mg-b-15">{{$filters.formatTimestamp(store.user.created_at)}}</div>
        <div v-if="store.id" class="title-red mg-b-5">{{$t('store.email')}}</div>
        <div v-if="store.id" class="text-dark mg-b-15">{{store.user.email}}</div>
        <div v-if="store.id" class="title-red mg-b-5">{{$t('store.status')}}</div>
        <div v-if="store.id" class="text-dark">{{store.status === 3 ? $t('global.pending') : (store.status === 2 ? $t('global.approved') : $t('global.rejected'))}}</div>

        <div class="title-red mg-b-5 mg-t-15">{{$t('store.business_type')}}</div>
        <div class="form-element">
          <select-input :value="store.business_type" :options="businessTypeOptions" label-key="label" value-key="value" @select="(option) => {
                onInput('business_type', option.value)
          }"/>
        </div>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-15">{{$t('store.tell_us_contact')}}</div>
        <div class="title-red mg-t-20 mg-b-10">{{$t('store.business_representative')}}*</div>
        <business-contact v-model="store.representative" :regions="regions"/>

        <div v-if="store.business_type === types.business.CORPORATION || store.business_type === types.business.PARTNERSHIP">
          <div class="flex align-center justify-between title-red mg-t-20 mg-b-10">{{$t('store.business_owners')}}* <strong class="gray pointer" v-if="store.owners.length < 4" @click="addOwner">{{$t('store.add_owner')}}</strong></div>
          <business-contact
            v-for="(item, index) in store.owners"
            :key="index"
            :has-title="true"
            :regions="regions"
            :has-equity="true"
            :title="$t('store.owner') + ' ' + (index + 1)"
            @delete="ownerRemove(index)"
            v-model="store.owners[index]"/>
        </div>

        <div class="flex align-start justify-between mg-b-15 mg-t-30">
          <div class="flex-1">
            <checkbox :checked="store.accepted_stripe_terms" @change="onInput('accepted_stripe_terms', !store.accepted_stripe_terms)" id="accepted_stripe_terms"><span v-html="$t('store.accepted_stripe_terms')"/></checkbox>
          </div>
        </div>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-15">{{$t('store.venue_about')}}</div>

        <div class="form-element mg-b-5" v-if="store.business_type !== types.business.INDIVIDUAL">
          <label>{{$t('store.registration_no')}}</label>
          <input v-model="store.reg_number" type="text" class="form-control" placeholder="12345678" required/>
        </div>
        <div class="form-element mg-b-5 mg-t-10" v-if="store.business_type !== types.business.INDIVIDUAL">
          <label>{{$t('store.company_number')}}</label>
          <input v-model="store.company_number" type="text" class="form-control" placeholder="1234567" required/>
        </div>
        <div class="form-element mg-b-5 mg-t-10">
          <label>{{$t('store.industry')}}</label>
          <input v-model="store.industry" type="text" class="form-control" required/>
        </div>
        <div class="flex align-start justify-between mg-b-5 mg-t-10" v-if="store.business_type !== types.business.INDIVIDUAL">
          <div class="flex-50">
            <div class="form-element">
              <label>{{$t('store.company_name_legal')}}</label>
              <span>{{$t('store.english_name')}}</span>
              <input v-model="store.company_legal" type="text" class="form-control" required/>
            </div>
          </div>
          <div class="flex-50">
            <div class="form-element">
              <label/>
              <span>{{$t('store.chinese_name')}}</span>
              <input v-model="store.company_legal_c" type="text" class="form-control" required/>
            </div>
          </div>
        </div>
        <div class="text-dark mg-b-5 mg-t-10">{{$t('store.venue_about_sub')}}</div>

        <div class="flex align-start justify-between mg-b-5 mg-t-10">
          <div class="flex-50">
            <div class="form-element">
              <label>{{$t('store.company_name')}}</label>
              <span>{{$t('store.english_name')}}</span>
              <input v-model="store.company" type="text" class="form-control" required/>
            </div>
          </div>
          <div class="flex-50">
            <div class="form-element">
              <label/>
              <span>{{$t('store.chinese_name')}}</span>
              <input v-model="store.company_c" type="text" class="form-control" required/>
            </div>
          </div>
        </div>

        <div class="form-element mg-b-5 mg-t-10">
          <label>{{$t('store.website')}}</label>
          <input v-model="store.website" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-5 mg-t-10">
          <label>{{$t('store.company_phone')}}</label>
          <input v-model="store.company_phone" type="text" pattern="\d*" maxlength="8" class="form-control" placeholder="12345678"  required/>
        </div>

        <div class="flex align-start justify-between mg-b-5 mg-t-10">
          <div class="flex-50">
            <div class="form-element">
              <label>{{$t('store.address')}}</label>
              <span>{{$t('store.english_name')}}</span>
              <input v-model="store.building" type="text" class="form-control" @blur="relocateMap" required/>
            </div>
          </div>
          <div class="flex-50">
            <div class="form-element">
              <label/>
              <span>{{$t('store.chinese_name')}}</span>
              <input v-model="store.building_c" type="text" class="form-control" @blur="relocateMap" required/>
            </div>
          </div>
        </div>

        <div class="flex align-start justify-between mg-b-5 mg-t-10">
          <div class="flex-50">
            <div class="form-element">
              <input v-model="store.road" type="text" class="form-control" @blur="relocateMap" required/>
            </div>
          </div>
          <div class="flex-50">
            <div class="form-element">
              <input v-model="store.road_c" type="text" class="form-control" @blur="relocateMap" required/>
            </div>
          </div>
        </div>

        <div class="flex align-start justify-between mg-b-5 mg-t-10">
          <div class="flex-50">
            <div class="form-element">
              <select-input :value="store.region" :options="regions" label-key="name" value-key="id" @select="(option) => {
                onInput('region', option, true)
                relocateMap()
              }"/>
            </div>
          </div>
          <div class="flex-50">
            <div class="form-element">
              <select-input :value="store.region" :options="regions" label-key="name_c" value-key="id" @select="(option) => {
                onInput('region', option, true)
                relocateMap()
              }"/>
            </div>
          </div>
        </div>

        <div class="flex align-start justify-between mg-b-5 mg-t-10">
          <div class="flex-50">
            <div class="form-element">
              <select-input :value="store.district" :options="district" label-key="name" value-key="id" @select="(option) => {
                onInput('district', option, true)
                relocateMap()
              }"/>
            </div>
          </div>
          <div class="flex-50">
            <div class="form-element">
              <select-input :value="store.district" :options="district" label-key="name_c" value-key="id" @select="(option) => {
                onInput('district', option, true)
                relocateMap()
              }"/>
            </div>
          </div>
        </div>
        <div class="mg-t-20 mg-b-20 profile__map" ref="map"/>

        <div class="text-dark mg-b-10 mg-t-20">{{$t('store.hours_of_op')}}</div>
        <div class="flex align-center justify-start">
          <div class="flex-20"/>
          <div class="flex-20 title-red">{{$t('store.open')}}</div>
          <div class="flex-20"/>
          <div class="flex-20 title-red">{{$t('store.close')}}</div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.monday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker ref="timer1" v-if="!isLoading" v-model="store.mon_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker ref="timer2" v-if="!isLoading" v-model="store.mon_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.tuesday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker ref="timer3" v-if="!isLoading" v-model="store.tue_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker ref="timer4" v-if="!isLoading" v-model="store.tue_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.wednesday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.wed_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.wed_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.thursday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.thu_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.thu_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.friday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.fri_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.fri_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.saturday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.sat_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.sat_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>
        <div class="flex align-center justify-start mg-b-10 mg-t-10">
          <div class="flex-20">{{$t('store.sunday')}}</div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.sun_open" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
          <div class="flex-20 dots"></div>
          <div class="flex-20">
            <div class="form-element">
              <vue-timepicker v-if="!isLoading" v-model="store.sun_close" :placeholder="$t('store.closed')" :hide-dropdown="true" :minute-interval="5" format="HH:mm"/>
            </div>
          </div>
        </div>

        <div class="form-element mg-b-10 mg-t-20">
          <label>{{$t('store.description')}}</label>
          <textarea v-model="store.description" @keypress="descriptionKeyPress" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-10 mg-t-20">
          <label>{{$t('store.description_c')}}</label>
          <textarea v-model="store.description_c" @keypress="descriptionKeyPress" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-5 mg-t-20">
          <label>{{$t('store.what_service')}}</label>
          <select-input :value="store.service" :options="services" label-key="name" value-key="id" @select="(val) => onInput('service', val)" required/>
        </div>

        <div class="title-gray mg-b-15">{{$t('store.what_features')}}</div>

        <div v-for="(service, index) in services" :key="index" class="flex align-start justify-start mg-b-5" :class="{ hidden: service.id !== store.service.id }">
          <div class="flex-20 text-dark bold">{{service.name}}</div>
          <div class="flex-80">
            <tags :value="store.features" :options="service.features" @select="(val) => onTagChange(index, val)"/>
          </div>
        </div>

        <div class="form-element mg-b-10 mg-t-20">
          <label>{{$t('store.other_features')}}</label>
          <textarea v-model="store.feature_description" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-10 mg-t-20">
          <label>{{$t('store.other_features_c')}}</label>
          <textarea v-model="store.feature_description_c" type="text" class="form-control" required/>
        </div>

        <div class="flex align-center justify-between mg-t-20 mg-b-10">
          <div class="flex-50">
            <div class="text-dark">{{$t('store.profile_pic')}}</div>
            <div class="subtitle-gray">{{$t('store.profile_pic_sub')}}</div>
          </div>
        </div>
        <image-select :default-value="store.avatar ? [store.avatar] : []" :btn-label="$t('store.set_picture')" @selected="(data) => onInput('avatar', data.images[0])"/>

        <div class="text-dark mg-t-20">{{$t('store.other_pics')}}</div>
        <div class="subtitle-gray mg-b-10">{{$t('store.other_pics_sub')}}</div>
        <image-select :multi="true" value-key="link" :default-value="store.images || []" @selected="(data) => onInput('images', data.images)"/>

        <div class="title-red mg-t-20 mg-b-10">{{$t('store.payment_title')}}</div>

        <div class="form-element mg-b-5">
          <span>{{$t('store.bank_name')}}</span>
          <input v-model="store.bank_name" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-5">
          <span>{{$t('store.bank_branch')}}</span>
          <input v-model="store.bank_branch" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-5 mg-t-10">
          <span>{{$t('store.bank_account_name')}}</span>
          <input v-model="store.bank_account_name" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-5 mg-t-10">
          <span>{{$t('store.bank_routing')}}</span>
          <input v-model="store.bank_routing" type="text" class="form-control" required/>
        </div>

        <div class="form-element mg-b-5 mg-t-10">
          <span>{{$t('store.bank_account_no')}}</span>
          <input v-model="store.bank_account_number" type="text" class="form-control"  placeholder="123 123456 123" required/>
        </div>

        <div class="form-element mg-b-5 mg-t-10">
          <span>{{$t('store.bank_currency')}}</span>
          <select-input :value="store.bank_currency" :options="currencies" @select="(val) => onInput('bank_currency', val)" required/>
        </div>

        <div class="flex justify-center mg-t-20">
          <button type="submit" class="btn" :class="{'btn--red': store.accepted_stripe_terms}" :disabled="!store.accepted_stripe_terms">{{$t('global.submit')}}</button>
        </div>
      </div>
      <div class="box mg-b-10">
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_edit_date')}}</div>
        <div class="text-dark">{{store.admin_updated_at ? $filters.formatTimestamp(store.admin_updated_at) : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_edit_email')}}</div>
        <div class="text-dark">{{store.admin_updated_by ? store.admin_updated_by.email : '-'}}</div>
      </div>
    </form>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import VueTimepicker from 'vue3-timepicker'
import Select from '@/components/select/select'
import Tags from '@/components/tags/tags'
import Loading from '@/components/loading/Loading'
import { Settings, Store } from '@/api/api'
import { businessTypes, types, currencies } from '@/config/config'
import _ from 'lodash'
import ImageSelect from '@/components/imageSelect/imageSelect'
import BusinessContact from '@/components/businessContact/businessContact'
import moment from 'moment-timezone'
import Checkbox from '@/components/checkbox/checkbox'

export default {
  name: 'ProfileEdit',
  components: {
    ImageSelect,
    tags: Tags,
    Loading,
    VueTimepicker,
    BusinessContact,
    checkbox: Checkbox,
    selectInput: Select
  },
  data () {
    return {
      isLoading: true,
      storeId: 0,
      services: [],
      regions: [],
      district: [],
      submitted: false,
      store: {
        user: {},
        representative: {},
        owners: [],
        images: [],
        features: [],
        service: {},
        region: {},
        district: {},
        mon_open: '',
        mon_close: '',
        tue_open: '',
        tue_close: '',
        wed_open: '',
        wed_close: '',
        thu_open: '',
        thu_close: '',
        fri_open: '',
        fri_close: '',
        sat_open: '',
        sat_close: '',
        sun_open: '',
        sun_close: ''
      }
    }
  },
  async mounted () {
    await this.loadGmaps()

    if (this.$route.params.id) {
      this.storeId = this.$route.params.id

      this.loadProduct()
    }
  },
  computed: {
    businessTypeOptions () {
      return _.cloneDeep(businessTypes).map((o) => {
        o.label = this.$t(o.label)
        return o
      })
    },
    types () {
      return types
    },
    currencies () {
      return currencies
    }
  },
  methods: {
    loadGmaps () {
      return new Promise((resolve, reject) => {
        if (window.google) {
          resolve(true)
        }

        const script = document.createElement('script')
        script.onload = () => {
          resolve(true)
        }
        script.async = true
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDKQUhF7BmkTk3iAqd4KLAD0kmFjG3HcaY&libraries=&v=weekly'
        document.head.appendChild(script)
      })
    },
    onInput (key, value, isObj) {
      this.store = {
        ...this.store,
        [key]: isObj ? { ...value } : value
      }

      if (key === 'region') {
        this.district = [...value.districts]
      }
    },
    descriptionKeyPress (e) {
      if (e.keyCode === 13) {
        const lineBreaks = (e.target.value.match(/(?:\r\n|\r|\n)/g) || []).length

        if (lineBreaks === 9) {
          e.preventDefault()
        }
      }
    },
    onTagChange (index, val) {
      this.services[index] = {
        ...this.services[index],
        features: val
      }
    },
    addOwner () {
      this.store.owners.push({})
    },
    ownerRemove (index) {
      this.store.owners.splice(index, 1)
    },
    async loadProduct () {
      this.isLoading = true

      try {
        const [merchant, services, regions] = await Promise.all([
          Store.getMine(this.storeId),
          Settings.getServices(),
          Settings.getRegions()
        ])

        services.data = services.data.filter(service => service.is_web_active == true)

        if (!merchant.data.id) {
          merchant.data = this.store
        }

        if (merchant.data.status === 3) {
          this.submitted = true
        }

        this.regions = regions.data

        const selectedRegion = regions.data.find((r) => r.id === merchant.data.region_id)

        if (selectedRegion) {
          this.district = selectedRegion.districts
        }

        const service = services.data.find((s) => s.id === merchant.data.service.id)

        merchant.data.service_name = service ? service.name : '-'
        merchant.data.features.forEach((f) => {
          const serviceIndex = services.data.findIndex((s) => s.id === f.service_id)

          if (serviceIndex !== -1) {
            services.data[serviceIndex].selected = true
          }
        })

        merchant.data.owners = merchant.data.owners.map((owner) => {
          owner.dob = moment(owner.dob).toDate()

          return owner
        })

        merchant.data.representative = merchant.data.representative ? {
          ...merchant.data.representative,
          dob: moment(merchant.data.representative.dob).toDate()
        } : {}

        this.store = { ...merchant.data }
        this.store.mon_open = '12:00'
        this.services = services.data

        window.setTimeout(this.relocateMap, 1000)
      } catch (err) {
        this.$toast.error(this.$t('store.load_profile_fail'))
      } finally {
        this.isLoading = false
        this.resetDropDowns()
      }
    },
    resetDropDowns () {
      const items = document.getElementsByClassName('drop-down')
      if (!items || !items.length) {
        window.setTimeout(() => this.resetDropDowns(), 250)
      }

      for (let i = 0; i < items.length; i++) {
        if (items[i]) {
          items[i].style.display = 'none'
        }
      }
    },
    relocateMap () {
      if (!this.map || !this.geocoder) {
        this.initMap()
      }

      let address = ''

      address += ', ' + this.store.road_c || this.store.road
      address += ', ' + this.store.building_c || this.store.building
      address += ', ' + this.store.region?.name
      address += ', ' + this.store.district?.name

      this.geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          this.map.setCenter(results[0].geometry.location)

          this.onInput('lat', results[0].geometry.location.lat())
          this.onInput('lng', results[0].geometry.location.lng())
          this.marker && this.marker.setMap(null)
          this.marker = new window.google.maps.Marker({
            map: this.map,
            position: results[0].geometry.location
          })
        } else {
          this.$toast.error('Failed to locate address on map')
        }
      })
    },
    initMap () {
      if (this.$refs.map) {
        this.map = new window.google.maps.Map(this.$refs.map, {
          zoom: 14,
          center: { lat: 22.315007, lng: 114.167163 }
        })

        this.geocoder = new window.google.maps.Geocoder()
      }
    },
    async savelAll () {
      this.isLoading = true

      try {
        const features = []
        this.services.forEach((s) => {
          s.features.forEach((f) => {
            if (f.selected) {
              features.push(f.id)
            }
          })
        })
        const store = { ...this.store, features: [...features] }

        if (store.business_type === types.business.PARTNERSHIP || store.business_type === types.business.CORPORATION) {
          if (!this.store.owners.length) {
            this.$toast.error(this.$t('store.select_business'))

            return
          }

          store.owners = store.owners.map((owner) => {
            owner.dob = moment(owner.dob).format('YYYY-MM-DD')

            owner.region_id = owner.region?.id
            owner.district_id = owner.district?.id

            return owner
          })
        } else {
          this.store.owners = []
        }

        store.representative = store.representative ? {
          ...store.representative,
          dob: moment(store.representative.dob).format('YYYY-MM-DD'),
          region_id: store.representative.region?.id,
          district_id: store.representative.district?.id
        } : null

        store.service_id = store.service ? store.service.id : 1
        store.region_id = store.region ? store.region.id : 1
        store.district_id = store.district ? store.district.id : 1

        let merchant = null

        if (this.store.id) {
          merchant = await Store.updateOne(this.store.id, store)
        } else {
          merchant = await Store.create(store)
        }

        const service = this.services.find((s) => s.id === merchant.data.service.id)

        merchant.data.service_name = service ? service.name : '-'

        this.store = merchant.data
        this.submitted = this.store.status === 3
        this.$toast.success(this.$t('store.store_save_success'))
      } catch (err) {
        this.$toast.error(this.$t('store.store_save_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
