<template>
  <div class="tags flex flex-wrap align-start justify-start">
    <div v-for="(item, index) in tags" :key="index" class="tag" :class="{ selected: item.selected }" @click="toggle(index)">{{item.name}}</div>
  </div>
</template>

<script>

export default {
  name: 'Tags',
  props: [
    'options',
    'value'
  ],
  data () {
    return {
      tags: []
    }
  },
  mounted () {
    this.tags = this.options.map((i) => {
      const exists = this.value.find((el) => el.id === i.id)

      i.selected = !!exists

      return i
    })
  },
  methods: {
    toggle (index) {
      const tags = [...this.tags]

      tags[index].selected = !tags[index].selected

      this.tags = [...tags]

      this.$emit('select', this.tags)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
